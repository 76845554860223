import React, { useState, useEffect } from "react";
import { auth } from '../Components/Firebase';
import Logo from '../Images/Logo.png';
import '../Styles/Auth/Login.css';
import { useNavigate } from "react-router-dom";

function Login() {
  const [email, setEmail] = useState('screen@teacherclick.co.il');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const nav = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        const schoolId = localStorage.getItem('School_Name');
        if (schoolId) {
          nav(`/Home/${schoolId}`);
        }
      }
    });
    return () => unsubscribe();
  }, [nav]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await auth.signInWithEmailAndPassword(email, password);
      console.log("התחברות מוצלחת!");
      nav('/SchoolCode')
    } catch (err) {
      setError("ההתחברות נכשלה. אנא בדוק את הפרטים ונסה שוב.");
    }
  };

  return (
    <div className="login-container">
      <div className="logo-container">
        <img src={Logo} alt="Logo" className="login-logo" />
      </div>
      <h2>Teacher Tab</h2>
      <h2>התחברות</h2>
      <form onSubmit={handleLogin}>
        <div className="input-group">
        </div>
        <div className="input-group">
          <label>סיסמה:</label>
          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button">התחבר</button>
      </form>
    </div>
  );
}

export default Login;
