import { Route, Routes } from "react-router-dom";
import Login from "./Auth/Login";
import SchoolCode from "./Auth/SchoolCode";
import Home from "./Pages/Home";

function App () {

  return (

    <Routes>

      <Route path="/" element={<Login/>}/>
      <Route path="/SchoolCode" element={<SchoolCode/>}/>
      <Route path="/Home/:schoolId" element={<Home/>}/>

    </Routes>

  )
}

export default App