import React, { useEffect, useState } from "react";
import Logo from '../Images/Logo.png';
import '../Styles/Pages/Home.css';
import { useParams } from "react-router-dom";
import { db } from "../Components/Firebase";

function Home() {
  const { schoolId } = useParams();
  const [teachers, setTeachers] = useState([]);
  const [currentTime, setCurrentTime] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [notes, setNotes] = useState('');


  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const querySnapshot = await db
          .collection("Teachers")
          .where("schoolName", "==", schoolId)
          .get();

        const fetchedTeachers = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const teacher = doc.data();
          const teacherId = doc.id;
          const classId = teacher.currentClass;

          let classData = { className: null, classManager: null };
          if (classId) {
            const classSnapshot = await db
              .collection("Schools")
              .doc(schoolId)
              .collection("Layers")
              .get();

            for (const layerDoc of classSnapshot.docs) {
              const classDoc = await layerDoc.ref.collection("Classes").doc(classId).get();
              if (classDoc.exists) {
                const classInfo = classDoc.data();
                classData.className = classInfo.name;
                if (classInfo.manager) {
                  const managerDoc = await db.collection("Teachers").doc(classInfo.manager).get();
                  if (managerDoc.exists) {
                    const managerData = managerDoc.data();
                    classData.classManager = `${managerData.firstName} ${managerData.lastName}`;
                  }
                }
                break;
              }
            }
          }
          
          return { ...teacher, classData };
        }));
        

        setTeachers(fetchedTeachers);
      } catch (error) {
        console.error("שגיאה בעת טעינת המורים:", error);
      } finally {
        setLoading(false);
      }
    };

    if (schoolId) {
      fetchTeachers();
    }
  }, [schoolId, teachers]);

  useEffect(() => {
    const unsubscribe = db
        .collection("Schools")
        .doc(schoolId)
        .onSnapshot((doc) => {
            if (doc.exists) {
                const schoolData = doc.data();
                const screenSettings = schoolData.screenSettings || {};
                setNotes(screenSettings.notes || '');
            }
        });

        

    return () => unsubscribe();
}, [schoolId]);

  

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formatter = new Intl.DateTimeFormat('he-IL', {
        dateStyle: 'long',
        timeStyle: 'medium',
      });
      setCurrentTime(formatter.format(now));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const getStatusColor = (status) => {
    switch (status) {
      case "זמין":
        return "#28a745";
      case "בשיעור":
        return "#ffc107";
      case "לא זמין":
        return "#dc3545";
      default:
        return "#555";
    }
  };

  const handleTeacherClick = (teacher) => {
    setSelectedTeacher(teacher);
  };

  const closeModal = () => {
    setSelectedTeacher(null);
  };

  if (loading) return <p>טוען...</p>;

  return (
    <React.Fragment>

      <div className="Head">
        <img src={Logo} alt="School Logo" />
        <div>
          <h1>{schoolId}</h1>
          <p>{currentTime}</p>
        </div>
      </div>

      {notes && <div className="notes-container">{notes}</div>}

      <div className="TeachersList" style={{ display: "flex", flexWrap: "wrap", gap: "20px", justifyContent: "center" }}>
        {teachers.length > 0 ? (
          teachers.map((teacher) => (
            <div key={teacher.id} className="TeacherCard" style={{ border: "1px solid #ddd", padding: "20px", borderRadius: "10px", textAlign: "center", width: "200px" }}>
              <img
                src={teacher.imageUrl || "default-teacher-avatar.png"}
                alt={`${teacher.firstName} ${teacher.lastName}`}
                className="TeacherImage"
                style={{ width: "200px", height: "200px", borderRadius: "50%" }}
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
              />
              <div className="TeacherInfo">
                <h2 style={{ fontSize: "18px", margin: "10px 0" }}>{`${teacher.firstName} ${teacher.lastName}`}</h2>
                <p style={{ fontSize: "16px", color: getStatusColor(teacher.status) }}>{teacher.status}</p>
                <p>כיתה: {teacher.classData.className || "לא מוקצה"}</p>
                <button
                  onClick={() => handleTeacherClick(teacher)}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}
                >
                  הצג פרטי מורה
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>לא נמצאו מורים עבור {schoolId}</p>
        )}
      </div>

      {selectedTeacher && (
        <div className="Modal" style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          direction: 'rtl'
        }}>
          <div className="ModalContent" style={{
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "10px",
            width: "80%",
            maxWidth: "600px",
            textAlign: "center"
          }}>
            <h2>{`${selectedTeacher.firstName} ${selectedTeacher.lastName}`}</h2>
            <img
              src={selectedTeacher.imageUrl || "default-teacher-avatar.png"}
              alt={`${selectedTeacher.firstName} ${selectedTeacher.lastName}`}
              style={{ width: "150px", height: "150px", borderRadius: "50%", objectFit: 'cover' }}
            />
            <p>סטטוס: <span style={{ color: getStatusColor(selectedTeacher.status) }}>{selectedTeacher.status}</span></p>
            <p>אימייל: {selectedTeacher.email}</p>
            <p>מספר טלפון: {selectedTeacher.phoneNumber}</p>

            <p>כיתה: {selectedTeacher.classData.className || "לא מוקצה"}</p>
            <p>מנהל כיתה: {selectedTeacher.classData.classManager || "לא זמין"}</p>
            <button
              onClick={closeModal}
              style={{
                padding: "10px 20px",
                backgroundColor: "#dc3545",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer"
              }}
            >
              סגור
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

export default Home;
