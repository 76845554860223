import React, { useState } from "react";
import { db } from '../Components/Firebase';
import '../Styles/Auth/SchoolCode.css'
import { useNavigate } from "react-router-dom";

function SchoolCode() {
  const [screencode, setscreencode] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [error, setError] = useState('');

  const nav = useNavigate()

  const handlescreencodeSubmit = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const querySnapshot = await db
        .collection("Schools")
        .where("Screen_Code", "==", screencode)
        .get();

      if (!querySnapshot.empty) {
        const schoolDoc = querySnapshot.docs[0].data();
        const schoolName = schoolDoc.School_Name;

        setSchoolName(schoolName);
        localStorage.setItem('School_Name', schoolName);

        console.log("שם בית הספר נמצא ונשמר ב-Local Storage:", schoolName);

        nav(`/Home/${schoolName}`)

        
      } else {
        setError("לא נמצא בית ספר עם הקוד שהוזן.");
      }
    } catch (error) {
      console.error("שגיאה בעת בדיקת קוד הטאב:", error);
      setError("אירעה שגיאה בעת בדיקת קוד הטאב.");
    }
  };

  return (
    <div className="school-code-container">
      <form onSubmit={handlescreencodeSubmit}>
        <label htmlFor="screencode">הזן קוד טאב:</label>
        <input
          type="text"
          id="screencode"
          value={screencode}
          onChange={(e) => setscreencode(e.target.value)}
          required
        />
        <button type="submit">בדוק בית ספר</button>
      </form>

      {schoolName && <p>שם בית הספר: {schoolName}</p>}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
}

export default SchoolCode;
